export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_CLEAN = 'LOGIN_CLEAN';
export const LOGIN_SUCCESS_FIRST = 'LOGIN_SUCCESS_FIRST';

export const ADD_USER_ATTEMPT = 'ADD_USER_ATTEMPT';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_LISTS_SUCCESS = 'ADD_USER_LISTS_SUCCESS';
export const ADD_USER_LISTS_ATTEMPT = 'ADD_USER_LISTS_ATTEMPT';
export const ADD_USER_LISTS_FAILED = 'ADD_USER_LISTS_FAILED';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const ADD_USER_CLEAN = 'ADD_USER_CLEAN';
