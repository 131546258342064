import {Container, Row} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";


export default function LoadingCustom() {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <CircularProgress/>
        </Row>
      </Container>
    </div>
  );
}
