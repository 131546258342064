import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import archers_reducers from "./archers_reducers";
import "./App.scss";
import LoadingCustom from "./common/LoadingCustom";

const loading = () => (
  <div>
    <LoadingCustom />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./archers_authentification/Login"));
const LoginInit = React.lazy(() =>
  import("./archers_authentification/LoginInit")
);

class App extends Component {
  render() {
    return (
      <Provider
        store={createStore(archers_reducers, {}, applyMiddleware(ReduxThunk))}
      >
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/LoginInit/:section"
                name="Change password"
                render={(props) => <LoginInit {...props} />}
              />
              <Route
                path=""
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
