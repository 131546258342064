import {
  LOGIN_ATTEMPT,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS_FIRST,
  LOGIN_CLEAN
} from '../archers_actions/types';


  const INITIAL_STATE = { message: '', isLogin:false,  messagePassword: '',loading: false,isLoginFirst:false, error: '', isAdmin: false , code: null}

  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case LOGIN_ATTEMPT:
        return {...INITIAL_STATE, loading: true }
      case LOGIN_FAILED:
        return {...INITIAL_STATE, loading: false, error: action.error  }
      case LOGIN_SUCCESS:
        return {...INITIAL_STATE, loading: false, message: action.message, isLogin:true ,code: action.code  }
      case LOGIN_SUCCESS_FIRST:
        return {...INITIAL_STATE, loading: false, message: action.message, isLoginFirst:true, code: action.code  }
      case LOGIN_CLEAN:
        return {INITIAL_STATE}
      default:
        return {...state};
    }
  }
